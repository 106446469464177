import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title></Title>
                <Text>
                    Arriba un gran seminari pels alumnes que anireu a la 36è promoció
                    <br />
                    <br />
                    Un seminari de sis hores de durada, amb explicacions detallades de La Llei
                    4/2015 i del codi penal.
                    <br />
                    <br />
                    Coneix les assignatures més importants i com aplicar De la Llei de Seguretat
                    Ciutadana 4/2015 i de la Llei orgànica 10/1995, del Codi Penal.
                    <br />
                    <br />
                    Dissabte 10 de desembre
                    <br />
                    <br />
                    De 9.00 a 15.00 h
                    <br />
                    <br />
                    <strong>6 hores de seminari</strong>
                    <br />
                    <br />
                    <strong>Dossier específic per a tots els assistents.</strong>
                    <br />
                    <br />
                    Una formació específica per al curs bàsic de l'Institut de Seguretat Pública de
                    Catalunya i les pràctiques bulevard.
                    <br />
                    <br />
                    El seminari s' imparteix a Hospitalet.
                    <br />
                    <br />
                    Al c/ Marti Codolar núm 18.
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
